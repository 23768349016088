/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext } from "react";
import { FiMapPin } from "react-icons/fi";
import { GlobalDataContext } from "../../context/context";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const Map = () => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="w-full mt-[60px]">
        {rpdata?.labels?.general?.titleMap ? (
          <h2 className="text-center pb-5">
            {rpdata?.labels?.general?.titleMap}
          </h2>
        ) : (
          <h2 className="text-center pb-5">
            We Cover {rpdata?.dbPrincipal?.miles} Miles Around{" "}
            {rpdata?.dbPrincipal?.location?.[0].address}
          </h2>
        )}
        <Tabs>
          <TabList>
            {rpdata?.dbPrincipal?.location?.map((item, index) => {
              return (
                <Tab key={index}>
                  <div className="flex items-center justify-center">
                    <FiMapPin className="text-[#ff0000] text-3xl" />
                    <h3 className="text-xl ml-2">{item.address}</h3>
                  </div>
                </Tab>
              );
            })}
          </TabList>
          {rpdata?.dbPrincipal?.location?.map((item, index) => {
            return (
              <TabPanel key={index}>
                <div className="w-full h-[400px]">
                  <iframe
                    src={item.url}
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                  ></iframe>
                </div>
              </TabPanel>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

export default Map;
